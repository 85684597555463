// App.js
import React from "react";
import { Helmet } from "react-helmet";
import Jotto from "../src/Jotto";
import "./App.css";
// import { SocketContext, socket } from "./context/socket";
import "@fontsource/clear-sans"; // Defaults to weight 400.

function App() {
  var api;
  if (process.env.NODE_ENV === "production") {
    api = "https://app.jottogame.com";
  } else {
    api = "http://localhost:3001";
  }

  return (
    <>
      {/* <SocketContext.Provider value={socket} api={api}> */}
      <Helmet>
        <meta name="robots" content="noindex" />
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css"
          integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm"
          crossOrigin="anonymous"
        />
        {/* <link
            rel="stylesheet"
            type="text/css"
            href="https://playjotto.s3.us-west-2.amazonaws.com/main.css"
          /> */}
      </Helmet>
      <Jotto api={api} />
      {/* </SocketContext.Provider> */}
    </>
  );
}
export default App;
