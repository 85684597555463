import React, {
  useState,
  // useContext,
  // useCallback,
  useEffect,
  useRef,
} from "react";
// import { SocketContext } from "./context/socket";
import { Button, Form, Modal } from "react-bootstrap";
import axios from "axios";
import logo from "./jotto.png";
import randomWords from "random-words";
import ReactTooltip from "react-tooltip";

import {
  // FiDelete,
  FiPlus,
  FiLogOut,
  FiBarChart2,
  FiLogIn,
} from "react-icons/fi";
import { IoIosRefresh, IoMdPerson } from "react-icons/io";
import { GiSwordsPower } from "react-icons/gi";
import { BsFillPersonPlusFill, BsTwitter } from "react-icons/bs";
import { FaShare, FaRobot, FaReddit, FaInstagram } from "react-icons/fa";
import { MdReportProblem, MdSms } from "react-icons/md";
import { AiOutlineMail } from "react-icons/ai";
import {
  RiDeleteBack2Line,
  // RiHashtag
} from "react-icons/ri";
// import { SiLetterboxd } from "react-icons/si";
import { BsFileWord, BsPencilFill } from "react-icons/bs";

var apiUrl;
var debugLevel;

if (process.env.NODE_ENV === "production") {
  apiUrl = "https://app.jottogame.com";
  debugLevel = "none";
} else {
  apiUrl = "http://localhost:3001";
  debugLevel = "debug";
}

// const alphabet = `ABCDEFGHIJKLMNOPQRSTUVWXYZ`;
const keyLayoutTop = "QWERTYUIOP";
const keyLayoutMid = "ASDFGHJKL";
const keyLayoutBot = "ZXCVBNM";

var myKeyLayoutTop = [];
for (var il0 = 0; il0 < keyLayoutTop.length; il0++) {
  myKeyLayoutTop.push(keyLayoutTop[il0]);
}

var myKeyLayoutMid = [];
for (var il1 = 0; il1 < keyLayoutMid.length; il1++) {
  myKeyLayoutMid.push(keyLayoutMid[il1]);
}

var myKeyLayoutBot = [];
// myKeyLayoutBot.push("Enter");
for (var il2 = 0; il2 < keyLayoutBot.length; il2++) {
  myKeyLayoutBot.push(keyLayoutBot[il2]);
}
myKeyLayoutBot.push(<RiDeleteBack2Line />);

const ESCAPE_KEYS = ["27", "Escape"];

const useEventListener = (eventName, handler, element = window) => {
  const savedHandler = useRef();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const eventListener = (event) => savedHandler.current(event);
    element.addEventListener(eventName, eventListener);
    return () => {
      element.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element]);
};

axios.interceptors.request.use(
  (config) => {
    const { origin } = new URL(config.url);
    const allowedOrigins = [apiUrl];
    const token = localStorage.getItem("token");
    if (allowedOrigins.includes(origin)) {
      config.headers.authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const Jotto = ({ api }) => {
  const apiUrl = api;
  const storedJwt = localStorage.getItem("token");
  const storedSubmitterId = localStorage.getItem("submitterId");
  const storedPseudonym = localStorage.getItem("pseudonym");
  const [Play, setPlay] = useState([]);
  const [GameData, setGameData] = useState([]);
  const [Games, setGames] = useState([]);
  const [fetchRes, setFetchRes] = useState(null);
  const [gameId, setGameId] = useState([]);
  const [jwt, setJwt] = useState(storedJwt || null);
  const [mobileWord, setMobileWord] = useState("");
  const [letterOne, setLetterOne] = useState("");
  const [letterTwo, setLetterTwo] = useState("");
  const [letterThree, setLetterThree] = useState("");
  const [letterFour, setLetterFour] = useState("");
  const [letterFive, setLetterFive] = useState("");
  const [matchDisplay, setMatchDisplay] = useState(false);
  const [opponentEmail, setOpponentEmail] = useState("");
  const [opponentPseudonym, setOpponentPseudonym] = useState("");
  const [pseudonym, setPseudonym] = useState(storedPseudonym || "");
  const [submitterId, setSubmitterId] = useState(storedSubmitterId || "");
  const [unusedTopLetters, setUnusedTopLetters] = useState([]);
  const [unusedMidLetters, setUnusedMidLetters] = useState([]);
  const [unusedBotLetters, setUnusedBotLetters] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [isShowGames, setShowGames] = useState(false);
  const [isGamePlay, setGamePlay] = useState(false);
  const [isGuest, setGuest] = useState(false);
  const [isJoinRandomGame, setJoinRandomGame] = useState(false);
  const [isJoinSpecificGame, setJoinSpecificGame] = useState(false);
  const [isLogin, setLogin] = useState(false);
  const [isLoggedOut, setLoggedOut] = useState(true);
  const [isMyGameNav, setMyGameNav] = useState(false);
  const [isMyUpdatesNav, setMyUpdatesNav] = useState(false);
  const [isNewGameNav, setNewGameNav] = useState(false);
  const [isRegister, setRegister] = useState(false);
  const [isShowKeyboard, setShowKeyboard] = useState(false);
  const [isShowShare, setShowShare] = useState(false);
  const [isShowFeedback, setShowFeedback] = useState(false);
  const [isStartRandomGame, setStartRandomGame] = useState(false);
  const [isStartJotBotGame, setStartJotBotGame] = useState(false);
  const [isStartSpecificGame, setStartSpecificGame] = useState(false);
  const [isStatNav, setStatNav] = useState(false);
  // const [isUpdatedGames, setUpdatedGames] = useState(false);
  // const [isUnjoinedGames, setUnjoinedGames] = useState(false);

  /** Socket and Event Emission */
  // const socket = useContext(SocketContext);

  // const populateGames = useCallback(
  //   (hasUpdates) => {
  //     if (debugLevel === "debug") {
  //       console.log("hasUpdates", true);
  //     }

  //     if (hasUpdates === true) {
  //       setUpdatedGames(true);
  //     } else if (hasUpdates === false) {
  //       setUpdatedGames(false);
  //     }
  //   },
  //   [setUpdatedGames]
  // );

  // const populateUnjoinedGames = useCallback(
  //   (hasInvites) => {
  //     if (hasInvites === true) {
  //       setUnjoinedGames(true);
  //       if (debugLevel === "debug") {
  //         console.log(true);
  //       }
  //     } else if (hasInvites === false) {
  //       setUnjoinedGames(false);
  //     }
  //   },
  //   [setUnjoinedGames]
  // );

  // useEffect(() => {
  //   if (pseudonym !== "") {
  //     socket.emit("USER_ONLINE", pseudonym);
  //     socket.emit("SEND_REFRESH_REQUEST", pseudonym);
  //   }
  //   // socket.on(pseudonym, populateUnjoinedGames);
  //   socket.on(pseudonym, populateGames);
  //   return () => {
  //     // socket.off(pseudonym, populateUnjoinedGames);
  //     socket.off(pseudonym, populateGames);
  //   };
  //   // }, [socket, pseudonym, populateUnjoinedGames]);
  // }, [socket, pseudonym, populateGames]);

  // async function socketEmit(event) {
  //   if (event) {
  //     event.preventDefault();
  //   }
  //   await socket.emit("SEND_REFRESH_REQUEST", pseudonym);
  //   // .then(() => {})
  //   // .catch(() => {});
  // }

  /** Registration */
  const getRegisterJwt = async (
    pseudonym,
    fullname,
    email,
    password,
    guest
  ) => {
    try {
      const { data } = await axios({
        method: "post",
        url: `${apiUrl}/user/register`,
        headers: {
          "content-type": "application/json",
        },
        data: {
          pseudonym: pseudonym,
          fullname: fullname,
          email: email,
          password: password,
          isGuest: guest,
        },
      });
      localStorage.setItem("token", data.token);
      localStorage.setItem("submitterId", data.id);
      localStorage.setItem("pseudonym", data.pseudonym);
      setJwt(data.token);
      setSubmitterId(data.id);
      setPseudonym(data.pseudonym);
      setFetchRes(null);
    } catch (err) {
      setFetchRes(err.response.data);
    }
  };

  /** Login */
  const getJwt = async (email, password) => {
    try {
      const { data } = await axios({
        method: "post",
        url: `${apiUrl}/user/login`,
        headers: {
          "content-type": "application/json",
          "Accept-Language": "en",
        },
        data: {
          email: email,
          password: password,
        },
        returning: true,
      });
      localStorage.setItem("submitterId", data.id);
      localStorage.setItem("pseudonym", data.pseudonym);
      localStorage.setItem("token", data.token);
      setSubmitterId(data.id);
      setPseudonym(data.pseudonym);
      setJwt(data.token);
      setLoggedOut(false);
      // const { invites } = await checkInvites(data.token, data.id);
    } catch (err) {
      setFetchRes(err.response.data);
    }
  };

  /* Components */
  function FormSubtitle(props) {
    return (
      <span className={"form-subtitle"}>
        In Jotto, you and your opponent each come up with a 5-letter word. Then,
        you alternate trying to guess the other's word. First to guess the
        other's word wins!
      </span>
    );
  }
  function NavButton(props) {
    return (
      <>
        {props.type === "nav" && (
          <li className="nav-item">
            <button
              className={`nav-link skewBtn blue ${props.classname}`}
              onClick={props.onclick}
              // data-tip={"game tip 0"}
              // data-tip={props.datatip}
            >
              {props.text}
            </button>
            {/* <ReactTooltip /> */}
          </li>
        )}
        {props.type === "game" && props.matchDisplay === null && (
          <>
            <button
              className={"nav-link skewBtn blue versus"}
              onClick={props.onclick}
              // data-tip={props.datatip}
              // data-tip={"game tip 1"}
              title={`Wordle-Jotto \n 
              In this game, matching letters will be revealed.\n 
              Yellow letters => correct letter, wrong position\n 
              Green letters => correct letter, correct position`}
            >
              <BsFileWord />
              {props.text} ({props.gameId})
            </button>
            {/* <ReactTooltip /> */}
          </>
        )}
        {props.type === "game" && props.matchDisplay === false && (
          <>
            <button
              className={"nav-link skewBtn blue versus"}
              onClick={props.onclick}
              // data-tip={props.datatip}
              // data-tip={"game tip 2"}
              title={`Classic Jotto \n 
              In this game, matching letters and positions will not be revealed\n
              The number next to your guess word is the number of matching letters\n`}
            >
              <BsPencilFill />
              {props.text} ({props.gameId})
            </button>
            {/* <ReactTooltip /> */}
          </>
        )}
        {props.type === "game" && props.matchDisplay === true && (
          <>
            <button
              className={"nav-link skewBtn blue versus"}
              onClick={props.onclick}
              // data-tip={props.tooltip}
              // data-tip={"game tip 3"}
              title={`Wordle-Jotto \n 
              In this game, matching letters will be revealed.\n 
              Yellow letters => correct letter, wrong position\n 
              Green letters => correct letter, correct position`}
            >
              <BsFileWord />
              {props.text} ({props.gameId})
            </button>
            {/* <ReactTooltip /> */}
          </>
        )}
      </>
    );
  }

  function LogoContainer(props) {
    return (
      <div className="container logo-container">
        <div className="navbar-brand">
          <img id="pic" src={logo} alt="logo" />
          <div className="logo-text">
            <span className={"logo-text-words"}>Wordle with</span>
            <div className={"row-letter-jotto correct"}>F</div>
            <div className={"row-letter-jotto sorta-correct"}>R</div>
            <div className={"row-letter-jotto sorta-correct"}>I</div>
            <div className={"row-letter-jotto sorta-correct"}>E</div>
            <div className={"row-letter-jotto sorta-correct"}>N</div>
            <div className={"row-letter-jotto sorta-correct"}>D</div>
            <div className={"row-letter-jotto correct"}>S</div>
          </div>
        </div>
      </div>
    );
  }

  function NavBar(props) {
    return (
      <nav className="navbar navbar-expand-lg navbar-light  py-3 shadow-sm">
        <div className="container">
          <div className="navmenu">
            {/* <ul className="nav justify-content-center"> */}
            <NavBarMenu status={props.status} />
            {/* </ul> */}
          </div>
        </div>
      </nav>
    );
  }

  function NavBarMenu(props) {
    return (
      <>
        {props.status === "loggedIn" && (
          <>
            <ul className="nav justify-content-center nav-loggedin">
              {/* {isUpdatedGames ? (
              <li className="nav-item"> */}
              <li className="nav-item">
                <button
                  // className={"nav-link nav-item skewBtn blue updates"}
                  className={"nav-link nav-item skewBtn blue"}
                  // onClick={() => getGames("unjoined+myturn")}
                  onClick={() => getGames("unjoined")}
                  // data-tip="View Game Invitations Here"
                >
                  <GiSwordsPower />{" "}
                  <span className={"button-text"}>Invites</span>
                </button>
                {/* <ReactTooltip /> */}
              </li>
              {/* ) : null} */}
              <li className="nav-item">
                <button
                  className={"nav-link nav-item skewBtn blue"}
                  onClick={() => showPlayNav("new")}
                  // data-tip="Start a New Game Here"
                >
                  <FiPlus /> <span className={"button-text"}>New Game</span>
                </button>
                {/* <ReactTooltip /> */}
              </li>
              <li className="nav-item">
                <button
                  className={"nav-link nav-item skewBtn blue"}
                  onClick={() => showPlayNav("current")}
                  // data-tip="View your Current Games Here"
                >
                  <IoMdPerson /> <span className={"button-text"}>My Games</span>
                </button>
                {/* <ReactTooltip /> */}
              </li>
              <li className="nav-item">
                <button
                  className={"nav-link nav-item skewBtn blue pseudonym"}
                  onClick={() => showPlayNav("stats")}
                  // data-tip="View Previously Played Games Here"
                >
                  <FiBarChart2 /> {pseudonym}
                  {/* {console.log("gameRes")} */}
                </button>
                {/* <ReactTooltip /> */}
              </li>
              <li className="nav-item 4">
                <button
                  className={"nav-link nav-item skewBtn blue"}
                  onClick={() => execLogoutLogic()}
                  // data-tip="Bye bye!"
                >
                  <FiLogOut /> <span className={"button-text"}>Log Out</span>
                </button>
                {/* <ReactTooltip /> */}
              </li>
            </ul>
          </>
        )}
        {props.status === "loggedOut" && (
          <>
            <ul className="nav justify-content-center nav-loggedout">
              <li className="nav-item">
                <button
                  className={"nav-link nav-item skewBtn blue loggedout"}
                  onClick={() => execPlayAsGuest()}
                  // data-tip="Play as a Guest account - note that you will not be able to finish Games once you leave the site."
                >
                  <BsFillPersonPlusFill />{" "}
                  <span className={"button-text"}>Play As Guest</span>
                </button>
                {/* <ReactTooltip /> */}
              </li>
              <li className="nav-item">
                <button
                  className={"nav-link nav-item skewBtn blue loggedout"}
                  onClick={() => execLoginLogic()}
                  // data-tip="Welcome back!"
                >
                  <FiLogIn /> <span className={"button-text"}>Login</span>
                </button>
                {/* <ReactTooltip /> */}
              </li>
            </ul>
          </>
        )}
      </>
    );
  }

  function GameNav(props) {
    return (
      <>
        {props.action === "new" && (
          <>
            {jwt !== null && isGuest !== true && (
              <>
                <NavButton
                  type="nav"
                  classname="gameplay-button-loggedin"
                  onclick={() => execCreateSpecificLogic()}
                  text="Challenge Opponent"
                  datatip="Challenge a specific opponent to a game of Jotto"
                />
                <NavButton
                  type="nav"
                  classname="gameplay-button-loggedin"
                  onclick={() => execCreateRandomLogic()}
                  text="Challenge Random"
                  datatip="Challenge a random opponent to a game of Jotto"
                />
              </>
            )}
            <NavButton
              type="nav"
              classname="gameplay-button-loggedin"
              onclick={() => execJoinRandomLogic()}
              text="Join Random"
              datatip="Challenge a random opponent to a game of Jotto"
            />
            <NavButton
              type="nav"
              classname="gameplay-button-loggedin"
              onclick={() => execCreateJotBotLogic()}
              text="Challenge JotBot"
              datatip="Challenge JotBot to a game of Jotto"
            />
          </>
        )}
        {props.action === "active" && (
          <>
            <NavButton
              type="nav"
              classname="gameplay-button-loggedin"
              onclick={() => getGames("myturn")}
              text="My Turn"
              datatip="View active games where it is your turn"
            />
            <NavButton
              type="nav"
              classname="gameplay-button-loggedin"
              onclick={() => getGames("notmyturn")}
              text="Their Turn"
              datatip="View active games where it is not your turn"
            />
          </>
        )}
        {props.action === "stats" && (
          <>
            <NavButton
              type="nav"
              classname="gameplay-button-loggedin"
              onclick={() => getGames("old")}
              text="Old Games"
              datatip="View your old games"
            />
          </>
        )}
        {props.action === "updates" && (
          <>
            <NavButton
              type="nav"
              classname="gameplay-button-loggedin"
              // onclick={() => getGames("unjoined+myturn")}
              onclick={() => getGames("unjoined")}
              text="View Invites"
              datatip="View challenges you have received"
            />
          </>
        )}
      </>
    );
  }

  /* Form Functions */
  // function clearTiles(e) {
  //   e.preventDefault();
  //   if (letterOne === "") {
  //   } else {
  //     setLetterOne("");
  //     setLetterTwo("");
  //     setLetterThree("");
  //     setLetterFour("");
  //     setLetterFive("");
  //   }
  // }

  var formEmail = "",
    formPass = "",
    formPseudonym = "",
    formName = "",
    formAccept = false;

  function changeHandler(e, form) {
    if (e) {
      e.preventDefault();
    }
    if (e === "") {
      formAccept = !formAccept;
    }
    if (form === "login") {
    } else if (form === "register") {
    } else if (form === "word") {
      setMobileWord(e.target.value);
    }
  }

  function inputHandler(e, type, form) {
    if (type === "email") {
      formEmail = e.target.value;
    } else if (type === "password") {
      formPass = e.target.value;
    } else if (type === "pseudonym") {
      formPseudonym = e.target.value;
    } else if (type === "name") {
      formName = e.target.value;
    } else if (type === "accept") {
      formAccept = e.target.value;
    }
    if (form === "login") {
      validateLoginForm(formEmail);
    } else if (form === "register") {
      validateRegisterForm(formEmail, formPseudonym, formPass);
    } else if (form === "mobileword") {
      validateMobileGuessForm();
    }
  }

  function handleRadio(e) {
    if (e === "letter-match") {
      setMatchDisplay(true);
    } else if (e === "number-match") {
      setMatchDisplay(false);
    }
  }

  // function setMobileWordUnbound(e) {
  //   e.preventDefault();
  //   if (e.target.value.length === 5) {
  //     setMobileWord(e.target.value);
  //   }
  // }

  /* Forms */
  const registerFormDiv = (
    <div className="register-form-container">
      <h3>Register</h3>
      <div className="Register">
        <Form className="RegisterForm" onSubmit={handleRegisterSubmit}>
          <Form.Group className="mb-3" controlId="formBasicPseudonym">
            <Form.Label>Pseudonym</Form.Label>
            <Form.Control
              autoFocus
              type="text"
              onBlur={(e) => inputHandler(e, "pseudonym", "register")}
              placeholder="Enter Pseudonym"
              onChange={(e) => changeHandler(e, "register")}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              onBlur={(e) => inputHandler(e, "name", "register")}
              placeholder="Enter Name"
              onChange={(e) => changeHandler(e, "register")}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              type="email"
              onBlur={(e) => inputHandler(e, "email", "register")}
              placeholder="Enter email"
              onChange={(e) => changeHandler(e, "register")}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              autoComplete="new-password"
              placeholder="Password"
              onBlur={(e) => inputHandler(e, "password", "register")}
              onChange={(e) => changeHandler(e, "register")}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <Form.Check
              type="checkbox"
              label="Create My Account"
              onChange={() => changeHandler("", "register")}
            />
          </Form.Group>
          <input
            variant="primary"
            type="submit"
            className="button nav-link nav-item blue"
            size="lg"
            value="Submit"
          />
        </Form>
      </div>
    </div>
  );

  const loginFormDiv = (
    <div className="login-form-container">
      <h3>Login</h3>
      <div className="Login">
        <Form className="LoginForm" onSubmit={handleLoginSubmit}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              autoFocus
              type="email"
              autoComplete="email"
              onBlur={(e) => inputHandler(e, "email", "login")}
              placeholder="Enter email"
              onChange={(e) => changeHandler(e, "login")}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              autoComplete="current-password"
              onBlur={(e) => inputHandler(e, "password", "login")}
              onChange={(e) => changeHandler(e, "login")}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <Form.Check
              type="checkbox"
              label="Keep Me Logged In"
              onChange={() => changeHandler("", "login")}
            />
          </Form.Group>
          <input
            variant="primary"
            type="submit"
            className="button nav-link nav-item blue"
            size="lg"
            value="Submit"
          />
        </Form>
      </div>
    </div>
  );

  /* Nav */
  const newGameNav = (
    <div className={"gameplay-container"}>
      <ul className="nav justify-content-center">
        <GameNav action="new"> {/* <ReactTooltip /> */}</GameNav>
      </ul>
    </div>
  );

  const myGameNav = (
    <div className={"gameplay-container"}>
      <ul className="nav justify-content-center">
        <GameNav action="active"> {/* <ReactTooltip /> */}</GameNav>
      </ul>
    </div>
  );

  const myUpdatesNav = (
    <div className={"gameplay-container"}>
      <ul className="nav justify-content-center">
        {/* <GameNav action="updates" /> */}
        <GameNav action="invites"> {/* <ReactTooltip /> */}</GameNav>
      </ul>
    </div>
  );

  const statNav = (
    <div className={"gameplay-container"}>
      <ul className="nav justify-content-center">
        <GameNav action="stats"> {/* <ReactTooltip /> */}</GameNav>
      </ul>
    </div>
  );

  const showAllGamesDiv = (
    <>
      {Games !== null && (
        <div className={"gameplay-container"}>
          <ul className="nav justify-content-center">
            {Games.map((Game) => (
              <li key={Game.id} className="nav-item">
                {submitterId === Game.user_1 && Game.match_display === false && (
                  <>
                    <NavButton
                      type="game"
                      onclick={() => loadGame(Game.id)}
                      text={`vs. ${Game.user_2_pseudonym} `}
                      gameId={Game.id}
                      matchDisplay={false}
                      datatip={`Play game vs ${Game.user_2_pseudonym}`}
                    />
                  </>
                )}
                {submitterId === Game.user_1 && Game.match_display === true && (
                  <>
                    <NavButton
                      type="game"
                      onclick={() => loadGame(Game.id)}
                      text={`vs. ${Game.user_2_pseudonym} `}
                      gameId={Game.id}
                      matchDisplay={true}
                      datatip={`Play game vs ${Game.user_2_pseudonym}`}
                    />
                  </>
                )}
                {submitterId === Game.user_1 && Game.match_display === null && (
                  <>
                    <NavButton
                      type="game"
                      onclick={() => loadGame(Game.id)}
                      text={`vs. ${Game.user_2_pseudonym} `}
                      gameId={Game.id}
                      matchDisplay={null}
                      datatip={`Play game vs ${Game.user_2_pseudonym}`}
                    />
                  </>
                )}

                {submitterId === Game.user_2 && Game.match_display === false && (
                  <>
                    <NavButton
                      type="game"
                      onclick={() => loadGame(Game.id)}
                      text={`vs. ${Game.user_1_pseudonym} `}
                      gameId={Game.id}
                      matchDisplay={false}
                      datatip={`Resume Classic Jotto-Style game vs ${Game.user_1_pseudonym}`}
                    />
                  </>
                )}
                {submitterId === Game.user_2 && Game.match_display === true && (
                  <>
                    <NavButton
                      type="game"
                      onclick={() => loadGame(Game.id)}
                      text={`vs. ${Game.user_1_pseudonym} `}
                      gameId={Game.id}
                      matchDisplay={true}
                      datatip={`Resume Wordle-Style game vs ${Game.user_1_pseudonym}`}
                    />
                  </>
                )}
                {submitterId === Game.user_2 && Game.match_display === null && (
                  <>
                    <NavButton
                      type="game"
                      onclick={() => loadGame(Game.id)}
                      text={`vs. ${Game.user_1_pseudonym} `}
                      gameId={Game.id}
                      matchDisplay={null}
                      datatip={`Resume Wordle-Style game vs ${Game.user_1_pseudonym}`}
                    />
                  </>
                )}
                {/* <ReactTooltip /> */}
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );

  const joinRandomGameDiv = (
    <div className={"gameplay-container"}>
      <div className="gameplay-div desktop-form">
        {/* <FormSubtitle /> */}
        <Form className="SubmitPlay" onSubmit={handleRandomJoinSubmit}>
          <Form.Group size="sm">
            <Form.Control
              hidden
              type="string"
              value={submitterId}
              onChange={(e) => setSubmitterId(e.target.value)}
            />
          </Form.Group>
          <Form.Label>Enter Your Word</Form.Label>
          <div className={"form-group row"}>
            <Form.Group size="lg">
              <Form.Control
                id="letterInput1"
                type="string"
                value={letterOne}
                htmlSize="1"
                maxLength="1"
                onChange={(e) => handleTextChange("1", e)}
              />
            </Form.Group>
            <Form.Group size="lg">
              <Form.Control
                id="letterInput2"
                type="string"
                value={letterTwo}
                htmlSize="1"
                maxLength="1"
                onChange={(e) => handleTextChange("2", e)}
              />
            </Form.Group>
            <Form.Group size="lg">
              <Form.Control
                id="letterInput3"
                type="string"
                value={letterThree}
                htmlSize="1"
                maxLength="1"
                onChange={(e) => handleTextChange("3", e)}
              />
            </Form.Group>
            <Form.Group size="lg">
              <Form.Control
                id="letterInput4"
                type="string"
                value={letterFour}
                htmlSize="1"
                maxLength="1"
                onChange={(e) => handleTextChange("4", e)}
              />
            </Form.Group>
            <Form.Group size="lg">
              <Form.Control
                id="letterInput5"
                type="string"
                value={letterFive}
                htmlSize="1"
                maxLength="1"
                onChange={(e) => setLetterFive(e.target.value.toUpperCase())}
              />
            </Form.Group>
            {/* <button className={"clear-btn"} onClick={(e) => clearTiles(e)}>
              <FiDelete />
            </button> */}
          </div>
          <div className="submit-container">
            <Button
              block="true"
              size="lg"
              type="submit"
              disabled={!validateGuessForm()}
            >
              Jotto!
            </Button>
          </div>
        </Form>
      </div>
      <div className="gameplay-div-mobile">
        {/* <FormSubtitle /> */}
        <Form className="SubmitPlay" onSubmit={handleRandomJoinSubmit}>
          <Form.Group size="sm">
            <Form.Control
              hidden
              type="string"
              value={submitterId}
              onChange={(e) => setSubmitterId(e.target.value)}
            />
          </Form.Group>
          <Form.Label>Enter Your Word</Form.Label>
          <div className={"form-group row"} key={"mobileWordDiv"}>
            <Form.Group size="lg">
              <Form.Control
                key={"mobileWord"}
                // autoFocus
                type="string"
                value={mobileWord.toUpperCase()}
                maxLength="5"
                onBlur={(e) => inputHandler(e, "word", "mobileword")}
                onChange={(e) => changeHandler(e, "word")}
              />
            </Form.Group>
          </div>
          <div className="submit-container">
            <Button
              block="true"
              size="lg"
              type="submit"
              disabled={!validateMobileGuessForm()}
            >
              Jotto!
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );

  const createRandomGameDiv = (
    <div className={"gameplay-container"}>
      <div className="gameplay-div desktop-form">
        <h3 className="form-title">Challenge Random Opponent</h3>
        {/* <FormSubtitle /> */}
        <Form className="SubmitPlay" onSubmit={handleRandomCreateSubmit}>
          <Form.Group size="sm">
            <Form.Control
              hidden
              type="string"
              value={submitterId}
              onChange={(e) => setSubmitterId(e.target.value)}
            />
          </Form.Group>
          <Form.Label>Select Game Type</Form.Label>
          <div className="radios btn-group" data-toggle="buttons">
            <Form.Label
              className="btn btn-secondary number-match"
              htmlFor="option1"
              data-tip="The number of correcty-guessed letters will be revealed after
              each turn"
            >
              <Form.Check
                type="radio"
                className="btn-check"
                name="options"
                id="option1"
                autoComplete="off"
                value="number-match"
                defaultChecked
                onChange={(e) => handleRadio(e.target.value)}
              />{" "}
              <span className="radio-label">Classic Jotto</span>
              <BsPencilFill />
            </Form.Label>
            <Form.Label
              className="btn btn-secondary letter-match"
              htmlFor="option2"
              data-tip="Correcty-guessed letters and their positions will be revealed after each turn"
            >
              <Form.Check
                type="radio"
                className="btn-check"
                name="options"
                id="option2"
                autoComplete="off"
                value="letter-match"
                onChange={(e) => handleRadio(e.target.value)}
              />
              <span className="radio-label">Wordle-Jotto</span>

              <BsFileWord />
            </Form.Label>
            <ReactTooltip className={"div-tooltip"} />
          </div>
          <Form.Label>Enter Your Word</Form.Label>

          <div className={"form-group row"}>
            <Form.Group size="lg">
              <Form.Control
                id="letterInput1"
                type="string"
                value={letterOne}
                htmlSize="1"
                maxLength="1"
                onChange={(e) => handleTextChange("1", e)}
              />
            </Form.Group>
            <Form.Group size="lg">
              <Form.Control
                id="letterInput2"
                type="string"
                value={letterTwo}
                htmlSize="1"
                maxLength="1"
                onChange={(e) => handleTextChange("2", e)}
              />
            </Form.Group>
            <Form.Group size="lg">
              <Form.Control
                id="letterInput3"
                type="string"
                value={letterThree}
                htmlSize="1"
                maxLength="1"
                onChange={(e) => handleTextChange("3", e)}
              />
            </Form.Group>
            <Form.Group size="lg">
              <Form.Control
                id="letterInput4"
                type="string"
                value={letterFour}
                htmlSize="1"
                maxLength="1"
                onChange={(e) => handleTextChange("4", e)}
              />
            </Form.Group>
            <Form.Group size="lg">
              <Form.Control
                id="letterInput5"
                type="string"
                value={letterFive}
                htmlSize="1"
                maxLength="1"
                onChange={(e) => setLetterFive(e.target.value.toUpperCase())}
              />
            </Form.Group>
            {/* <button className={"clear-btn"} onClick={(e) => clearTiles(e)}>
              <FiDelete />
            </button> */}
          </div>
          <div className="submit-container">
            <Button
              block="true"
              size="lg"
              type="submit"
              disabled={!validateGuessForm()}
            >
              Jotto!
            </Button>
          </div>
        </Form>
      </div>
      <div className="gameplay-div-mobile">
        <h3 className="form-title">Challenge Random Opponent</h3>
        {/* <FormSubtitle /> */}
        <Form className="SubmitPlay" onSubmit={handleRandomCreateSubmit}>
          <Form.Group size="sm">
            <Form.Control
              hidden
              type="string"
              value={submitterId}
              onChange={(e) => setSubmitterId(e.target.value)}
            />
          </Form.Group>
          <Form.Label>Select Game Type</Form.Label>
          <div className="radios btn-group" data-toggle="buttons">
            <Form.Label
              className="btn btn-secondary number-match"
              htmlFor="option1"
              data-tip-html="In Classic Jotto, you come up with a word, and your opponent will try to guess it!     
              Your opponnent comes up with a word, and you try to guess it!     
              The number of correcty-guessed letters will be revealed after
              each turn.     First to guess the other's word wins!"
            >
              <Form.Check
                type="radio"
                className="btn-check"
                // disabled
                name="options"
                id="option1"
                autoComplete="off"
                value="number-match"
                defaultChecked
                onChange={(e) => handleRadio(e.target.value)}
              />{" "}
              <span className="radio-label">Classic Jotto</span>
              <BsPencilFill />
            </Form.Label>
            <Form.Label
              className="btn btn-secondary letter-match"
              htmlFor="option2"
              data-tip="Correcty-guessed letters and their positions will be revealed after each turn"
            >
              <Form.Check
                type="radio"
                className="btn-check"
                name="options"
                id="option2"
                autoComplete="off"
                value="letter-match"
                onChange={(e) => handleRadio(e.target.value)}
              />
              <span className="radio-label">Wordle-Jotto</span>

              <BsFileWord />
            </Form.Label>
            {/* <ReactTooltip /> */}
          </div>
          <Form.Label>Enter Your Word</Form.Label>
          <div className={"form-group row"} key={"mobileWordDiv"}>
            <Form.Group size="lg">
              <Form.Control
                key={"mobileWord"}
                // autoFocus
                type="string"
                value={mobileWord.toUpperCase()}
                maxLength="5"
                onBlur={(e) => inputHandler(e, "word", "mobileword")}
                onChange={(e) => changeHandler(e, "word")}
              />
            </Form.Group>
          </div>
          <div className="submit-container">
            <Button
              block="true"
              size="lg"
              type="submit"
              disabled={!validateMobileGuessForm()}
            >
              Jotto!
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );

  const createChallengeJotBot = (
    <div className={"gameplay-container"}>
      <div className="gameplay-div desktop-form">
        <h3 className="form-title">Challenge JotBot</h3>
        {/* <FormSubtitle /> */}
        <Form className="SubmitPlay" onSubmit={handleJotBotCreateSubmit}>
          <Form.Group size="sm">
            <Form.Control
              hidden
              type="string"
              value={submitterId}
              onChange={(e) => setSubmitterId(e.target.value)}
            />
          </Form.Group>
          <Form.Label>Select Game Type</Form.Label>
          <div className="radios btn-group" data-toggle="buttons">
            {/* <Form.Label
              className="btn btn-secondary number-match"
              htmlFor="option1"
              data-tip="The number of correcty-guessed letters will be revealed after
              each turn"
            >
              <Form.Check
                type="radio"
                // disabled
                className="btn-check"
                name="options"
                id="option1"
                autoComplete="off"
                value="number-match"
                defaultChecked
                onChange={(e) => handleRadio(e.target.value)}
              />{" "}
              <span className="radio-label">Classic Jotto</span>
              <BsPencilFill />
            </Form.Label> */}
            <Form.Label
              className="btn btn-secondary letter-match"
              htmlFor="option2"
              data-tip="Correcty-guessed letters and their positions will be revealed after each turn"
            >
              <Form.Check
                type="radio"
                className="btn-check"
                name="options"
                id="option2"
                autoComplete="off"
                value="letter-match"
                onChange={(e) => handleRadio(e.target.value)}
              />
              <span className="radio-label">Wordle-Jotto</span>

              <BsFileWord />
            </Form.Label>
            {/* <ReactTooltip /> */}
          </div>
          <Form.Label>Enter Your Word</Form.Label>

          <div className={"form-group row"}>
            <Form.Group size="lg">
              <Form.Control
                id="letterInput1"
                type="string"
                value={letterOne}
                htmlSize="1"
                maxLength="1"
                onChange={(e) => handleTextChange("1", e)}
              />
            </Form.Group>
            <Form.Group size="lg">
              <Form.Control
                id="letterInput2"
                type="string"
                value={letterTwo}
                htmlSize="1"
                maxLength="1"
                onChange={(e) => handleTextChange("2", e)}
              />
            </Form.Group>
            <Form.Group size="lg">
              <Form.Control
                id="letterInput3"
                type="string"
                value={letterThree}
                htmlSize="1"
                maxLength="1"
                onChange={(e) => handleTextChange("3", e)}
              />
            </Form.Group>
            <Form.Group size="lg">
              <Form.Control
                id="letterInput4"
                type="string"
                value={letterFour}
                htmlSize="1"
                maxLength="1"
                onChange={(e) => handleTextChange("4", e)}
              />
            </Form.Group>
            <Form.Group size="lg">
              <Form.Control
                id="letterInput5"
                type="string"
                value={letterFive}
                htmlSize="1"
                maxLength="1"
                onChange={(e) => setLetterFive(e.target.value.toUpperCase())}
              />
            </Form.Group>
            {/* <button className={"clear-btn"} onClick={(e) => clearTiles(e)}>
              <FiDelete />
            </button> */}
          </div>
          <div className="submit-container">
            <Button
              block="true"
              size="lg"
              type="submit"
              disabled={!validateGuessForm()}
            >
              Jotto!
            </Button>
          </div>
        </Form>
      </div>
      <div className="gameplay-div-mobile">
        <h3 className="form-title">Challenge JotBot</h3>
        {/* <FormSubtitle /> */}
        <Form className="SubmitPlay" onSubmit={handleJotBotCreateSubmit}>
          <Form.Group size="sm">
            <Form.Control
              hidden
              type="string"
              value={submitterId}
              onChange={(e) => setSubmitterId(e.target.value)}
            />
          </Form.Group>
          <Form.Label>Select Game Type</Form.Label>
          <div className="radios btn-group" data-toggle="buttons">
            {/* <Form.Label
              className="btn btn-secondary number-match"
              htmlFor="option1"
              // data-tip="The number of correcty-guessed letters will be revealed after each turn"
            >
              <Form.Check
                type="radio"
                disabled
                className="btn-check"
                name="options"
                id="option1"
                autoComplete="off"
                value="number-match"
                // defaultChecked
                // onChange={(e) => handleRadio(e.target.value)}
              />{" "}
              <span className="radio-label">Classic Jotto</span>
              <BsPencilFill />
            </Form.Label> */}
            <Form.Label
              className="btn btn-secondary letter-match"
              htmlFor="option1"
              data-tip="Correcty-guessed letters and their positions will be revealed after each turn"
            >
              <Form.Check
                type="radio"
                className="btn-check"
                name="options"
                id="option1"
                autoComplete="on"
                value="letter-match"
                defaultChecked
                onChange={(e) => handleRadio(e.target.value)}
              />
              <span className="radio-label">Wordle-Jotto</span>

              <BsFileWord />
            </Form.Label>
            {/* <ReactTooltip /> */}
          </div>
          <Form.Label>Enter Your Word</Form.Label>
          <div className={"form-group row"} key={"mobileWordDiv"}>
            <Form.Group size="lg">
              <Form.Control
                key={"mobileWord"}
                // autoFocus
                type="string"
                value={mobileWord.toUpperCase()}
                maxLength="5"
                onBlur={(e) => inputHandler(e, "word", "mobileword")}
                onChange={(e) => changeHandler(e, "word")}
              />
            </Form.Group>
          </div>
          <div className="submit-container">
            <Button
              block="true"
              size="lg"
              type="submit"
              disabled={!validateMobileGuessForm()}
            >
              Jotto!
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );

  const createSpecificGameDiv = (
    <div className={"gameplay-container"}>
      <div className="gameplay-div desktop-form">
        <h3 className="form-title">Challenge Opponent</h3>
        {/* <FormSubtitle /> */}
        <Form className="SubmitPlay" onSubmit={handleSpecificCreateSubmit}>
          <Form.Label>Select Game Type</Form.Label>
          <div className="radios btn-group" data-toggle="buttons">
            <Form.Label
              className="btn btn-secondary number-match"
              htmlFor="option1"
              data-tip="The number of correcty-guessed letters will be revealed after
              each turn"
            >
              <Form.Check
                type="radio"
                className="btn-check"
                name="options"
                id="option1"
                autoComplete="off"
                value="number-match"
                defaultChecked
                onChange={(e) => handleRadio(e.target.value)}
              />{" "}
              <span className="radio-label">Classic Jotto</span>
              <BsPencilFill />
            </Form.Label>
            <Form.Label
              className="btn btn-secondary letter-match"
              htmlFor="option2"
              data-tip="Correcty-guessed letters and their positions will be revealed after each turn"
            >
              <Form.Check
                type="radio"
                className="btn-check"
                name="options"
                id="option2"
                autoComplete="off"
                value="letter-match"
                onChange={(e) => handleRadio(e.target.value)}
              />
              <span className="radio-label">Wordle-Jotto</span>

              <BsFileWord />
            </Form.Label>
          </div>
          <Form.Label>Select Opponent</Form.Label>
          <Form.Select
            id="selectOpponent"
            className="mt-2"
            onChange={(e) => setOpponentPseudonym(e.currentTarget.value)}
          >
            {usersList.map((p) => (
              <option key={p} value={p}>
                {p}
              </option>
            ))}
          </Form.Select>
          <Form.Group size="lg">
            <Form.Control
              hidden
              type="string"
              value={opponentEmail}
              onChange={(e) => setOpponentEmail(e.target.value)}
            />
          </Form.Group>
          <Form.Group size="sm">
            <Form.Control
              hidden
              type="string"
              value={submitterId}
              onChange={(e) => setSubmitterId(e.target.value)}
            />
          </Form.Group>
          <Form.Label>Enter Your Word</Form.Label>
          <div className={"form-group row"}>
            <Form.Group size="lg">
              <Form.Control
                id="letterInput1"
                type="string"
                value={letterOne}
                htmlSize="1"
                maxLength="1"
                onChange={(e) => handleTextChange("1", e)}
              />
            </Form.Group>
            <Form.Group size="lg">
              <Form.Control
                id="letterInput2"
                type="string"
                value={letterTwo}
                htmlSize="1"
                maxLength="1"
                onChange={(e) => handleTextChange("2", e)}
              />
            </Form.Group>
            <Form.Group size="lg">
              <Form.Control
                id="letterInput3"
                type="string"
                value={letterThree}
                htmlSize="1"
                maxLength="1"
                onChange={(e) => handleTextChange("3", e)}
              />
            </Form.Group>
            <Form.Group size="lg">
              <Form.Control
                id="letterInput4"
                type="string"
                value={letterFour}
                htmlSize="1"
                maxLength="1"
                onChange={(e) => handleTextChange("4", e)}
              />
            </Form.Group>
            <Form.Group size="lg">
              <Form.Control
                id="letterInput5"
                type="string"
                value={letterFive}
                htmlSize="1"
                maxLength="1"
                onChange={(e) => setLetterFive(e.target.value.toUpperCase())}
              />
            </Form.Group>
            {/* <button className={"clear-btn"} onClick={(e) => clearTiles(e)}>
              <FiDelete />
            </button> */}
          </div>
          <div className="submit-container">
            <Button
              block="true"
              size="lg"
              type="submit"
              disabled={!validateGuessForm()}
            >
              Jotto!
            </Button>
          </div>
        </Form>
      </div>
      <div className="gameplay-div-mobile">
        <h3 className="form-title">Challenge Opponent</h3>
        {/* <FormSubtitle /> */}
        <Form className="SubmitPlay" onSubmit={handleSpecificCreateSubmit}>
          <Form.Label>Select Game Type</Form.Label>
          <div className="radios btn-group" data-toggle="buttons">
            <Form.Label
              className="btn btn-secondary number-match"
              htmlFor="option1"
              data-tip="The number of correcty-guessed letters will be revealed after
              each turn"
            >
              <Form.Check
                type="radio"
                className="btn-check"
                name="options"
                id="option1"
                autoComplete="off"
                value="number-match"
                defaultChecked
                onChange={(e) => handleRadio(e.target.value)}
              />{" "}
              <span className="radio-label">Classic Jotto</span>
              <BsPencilFill />
            </Form.Label>
            <Form.Label
              className="btn btn-secondary letter-match"
              htmlFor="option2"
              data-tip="Correcty-guessed letters and their positions will be revealed after each turn"
            >
              <Form.Check
                type="radio"
                className="btn-check"
                name="options"
                id="option2"
                autoComplete="off"
                value="letter-match"
                onChange={(e) => handleRadio(e.target.value)}
              />
              <span className="radio-label">Wordle-Jotto</span>
              <BsFileWord />
            </Form.Label>
          </div>
          <Form.Label>Select Opponent</Form.Label>
          <Form.Select
            id="selectOpponent"
            className="mt-2"
            onChange={(e) => setOpponentPseudonym(e.currentTarget.value)}
          >
            {usersList.map((p) => (
              <option key={p} value={p}>
                {p}
              </option>
            ))}
          </Form.Select>
          <Form.Group size="lg">
            <Form.Control
              hidden
              type="string"
              value={opponentEmail}
              onChange={(e) => setOpponentEmail(e.target.value)}
            />
          </Form.Group>
          <Form.Group size="sm">
            <Form.Control
              hidden
              type="string"
              value={submitterId}
              onChange={(e) => setSubmitterId(e.target.value)}
            />
          </Form.Group>
          <Form.Label>Enter Your Word</Form.Label>
          <div className={"form-group row"} key={"mobileWordDiv"}>
            <Form.Group size="lg">
              <Form.Control
                key={"mobileWord"}
                // autoFocus
                type="string"
                value={mobileWord.toUpperCase()}
                maxLength="5"
                onBlur={(e) => inputHandler(e, "word", "mobileword")}
                onChange={(e) => changeHandler(e, "word")}
              />
            </Form.Group>
          </div>
          <div className="submit-container">
            <Button
              block="true"
              size="lg"
              type="submit"
              disabled={!validateMobileGuessForm()}
            >
              Jotto!
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );

  const showResDiv = <div className={"gameplay-feedback"}>{fetchRes}</div>;

  /* Share */
  function handleShareClose() {
    setShowShare(false);
  }

  /* Share */
  function handleFeedbackClose() {
    setShowFeedback(false);
  }
  const shareDiv = (
    <div className={"modal-div"}>
      <>
        <Modal show={isShowShare}>
          <Modal.Header>
            <Modal.Title>Share Jotto!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <button
              href={`mailto:&subject=Jotto?has?arrived!&body=Join the Jotto fun! <br/> <a href="https://play.jottogame.com">Join Here</a>`}
              className={"shareButton nav-link nav-item skewBtn blue"}
              onClick={() => shareJot()}
            > */}
            <a
              href={`mailto:?subject=Jotto has arrived!&body=Join the Jotto fun at https://play.jottogame.com`}
              className={"shareIcon"}
              onClick={() => shareJot()}
            >
              <AiOutlineMail />
            </a>
            {/* </button> */}
            {/* <button
              href={
                "&sms:&body=Join%20the%20Jotto%20fun!%20%0Ahttps%3A%2F%2Fplay.jottogame.com"
              }
              className={"shareButton nav-link nav-item skewBtn blue"}
            > */}
            <a
              href={
                "sms:&body=Join%20the%20Jotto%20fun!%20%0Ahttps%3A%2F%2Fplay.jottogame.com"
              }
              className={"shareIcon"}
            >
              <MdSms />
            </a>
            {/* <a
              href={
                "sms:&body=Join%20the%20Jotto%20fun!%20%0Ahttps%3A%2F%2Fplay.jottogame.com"
              }
              className={"shareIcon"}
            >
              <FaReddit />
            </a>
            <a
              href={
                "sms:&body=Join%20the%20Jotto%20fun!%20%0Ahttps%3A%2F%2Fplay.jottogame.com"
              }
              className={"shareIcon"}
            >
              <BsTwitter />
            </a>
            <a
              href={
                "sms:&body=Join%20the%20Jotto%20fun!%20%0Ahttps%3A%2F%2Fplay.jottogame.com"
              }
              className={"shareIcon"}
            >
              <FaInstagram />
            </a> */}

            {/* </button> */}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleShareClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    </div>
  );

  const feedbackDiv = (
    <div className={"modal-div"}>
      <>
        <Modal show={isShowFeedback} className={"feedback-modal-div"}>
          {/* <Modal.Header>
            <Modal.Title>Report A Bug</Modal.Title>
          </Modal.Header> */}
          {/* <Modal.Body> */}
          {/* <div className={"ZohoBugForm"}> */}
          <iframe
            // frameBorder="0"
            // style="height:500px;width:99%;border:none;"
            src="https://forms.zohopublic.com/jotto/form/BugTracker/formperma/QEn8awemVjAJE0pTkpQZp1k8BKf4iYpTfSGtnsIrmok"
          />
          {/* </div> */}
          {/* <div className="ReportBugs">
              <Form
                className="BugsForm"
                onSubmit={console.log("handleReportBugs")}
              >
                <Form.Group className="mb-3" controlId="formBasicBugDetail">
                  <Form.Label>Bug Details</Form.Label>
                  <Form.Control
                    autoFocus
                    type="textarea"
                    onBlur={(e) => inputHandler(e, "bug_detail", "bug_form")}
                    placeholder="Enter Details of Bug"
                    onChange={(e) => changeHandler(e, "bug_form")}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicBugScreenshot">
                  <Form.Label>Upload Screenshot(s)</Form.Label>
                  <Form.Control
                    autoFocus
                    type="file"
                    onBlur={(e) => inputHandler(e, "bug_screens", "bug_form")}
                    onChange={(e) => changeHandler(e, "bug_form")}
                  />
                </Form.Group>
                <input
                  variant="primary"
                  type="submit"
                  className="button nav-link nav-item blue"
                  size="lg"
                  value="Send"
                />
              </Form>
            </div> */}
          {/* </button> */}
          {/* </Modal.Body> */}
          <Modal.Footer>
            <Button variant="secondary" onClick={handleFeedbackClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    </div>
  );

  /* Gameplay*/
  function clickLetter(value, e) {
    e.preventDefault();
    if (value.toString() === "[object Object]") {
      if (letterFive !== "") {
        setLetterFive("");
      } else if (letterFour !== "") {
        setLetterFour("");
      } else if (letterThree !== "") {
        setLetterThree("");
      } else if (letterTwo !== "") {
        setLetterTwo("");
      } else if (letterOne !== "") {
        setLetterOne("");
      }
    } else {
      if (letterOne === "") {
        setLetterOne(value);
      } else if (letterTwo === "") {
        setLetterTwo(value);
      } else if (letterThree === "") {
        setLetterThree(value);
      } else if (letterFour === "") {
        setLetterFour(value);
      } else if (letterFive === "") {
        setLetterFive(value);
      }
    }
  }

  const handler = ({ key }) => {
    try {
      if (isShowKeyboard === true) {
        if (key === "Tab") {
        } else if (key === "Enter") {
          if (debugLevel === "debug") {
            // console.log("key", key);
          }
          if (isJoinRandomGame === true) {
            if (letterFive !== "") {
              handleRandomJoinSubmit();
            }
          } else if (isJoinSpecificGame === true) {
            if (letterFive !== "") {
              handleSpecificJoinSubmit();
            }
          } else if (isStartSpecificGame === true) {
            if (letterFive !== "") {
              handleSpecificCreateSubmit();
            }
          } else if (isStartRandomGame === true) {
            if (letterFive !== "") {
              handleRandomCreateSubmit();
            }
          } else if (isGamePlay === true) {
            if (letterFive !== "") {
              handlePlaySubmit();
            }
          }
        } else if (key === "Backspace") {
          if (letterFive !== "") {
            setLetterFive("");
          } else if (letterFour !== "") {
            setLetterFour("");
          } else if (letterThree !== "") {
            setLetterThree("");
          } else if (letterTwo !== "") {
            setLetterTwo("");
          } else if (letterOne !== "") {
            setLetterOne("");
          }
        } else if (ESCAPE_KEYS.includes(String(key))) {
        } else {
          if (letterOne === "") {
            setLetterOne(key.toUpperCase());
          } else if (letterTwo === "") {
            setLetterTwo(key.toUpperCase());
          } else if (letterThree === "") {
            setLetterThree(key.toUpperCase());
          } else if (letterFour === "") {
            setLetterFour(key.toUpperCase());
          } else if (letterFive === "") {
            setLetterFive(key.toUpperCase());
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEventListener("keydown", handler);

  function LetterTile(props) {
    return (
      <>
        <button
          key={props.match}
          id={props.match}
          className={"letter"}
          onClick={(e) => clickLetter(props.match, e)}
        >
          {props.match}
        </button>
      </>
    );
  }

  function Keyboard(props) {
    return (
      <>
        <div className={"letters-container"}>
          <div className={"letters-unused container"}>
            <div className={"letters-row"} id={"top-row-keys"}>
              {props.unusedTopLetters.map((match) => (
                <LetterTile match={match} key={match} />
              ))}
            </div>
            <div className={"letters-row"} id={"mid-row-keys"}>
              {props.unusedMidLetters.map((match) => (
                <LetterTile match={match} key={match} />
              ))}
            </div>
            <div className={"letters-row"} id={"bot-row-keys"}>
              {props.unusedBotLetters.map((match) => (
                <LetterTile match={match} key={match} />
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }

  const myKeyboardDiv = (
    <>
      <div className={"keyboard-container"}>
        <Keyboard
          unusedTopLetters={unusedTopLetters}
          unusedMidLetters={unusedMidLetters}
          unusedBotLetters={unusedBotLetters}
        />
      </div>
    </>
  );

  const handleTextChange = (val, e) => {
    e.preventDefault();
    var fieldNum = Number(val);
    if (fieldNum === 1) {
      setLetterOne(e.target.value.toUpperCase());
    } else if (fieldNum === 2) {
      setLetterTwo(e.target.value.toUpperCase());
    } else if (fieldNum === 3) {
      setLetterThree(e.target.value.toUpperCase());
    } else if (fieldNum === 4) {
      setLetterFour(e.target.value.toUpperCase());
    }
    document.getElementById(`letterInput${fieldNum + 1}`).focus();
  };

  /* Resetters */
  function ResetLetterSetters(props) {
    setLetterOne("");
    setLetterTwo("");
    setLetterThree("");
    setLetterFour("");
    setLetterFive("");
    setMobileWord("");
    // setMatchingLetters([]);
    // setNonmatchingLetters([]);
    // setUnusedLetters(myAlphabet);
    setUnusedTopLetters(myKeyLayoutTop);
    setUnusedMidLetters(myKeyLayoutMid);
    setUnusedBotLetters(myKeyLayoutBot);
    setShowKeyboard(false);
  }

  function ResetGameSetters(props) {
    setStartRandomGame(false);
    setStartSpecificGame(false);
    setJoinRandomGame(false);
    setJoinSpecificGame(false);
    setStartJotBotGame(false);
  }

  function SetPostSetters(props) {
    ResetGameSetters();
    ResetLetterSetters();
    setGameId("");
    setShowGames(false);
    setGamePlay(false);
  }

  const execRegisterLogic = () => {
    ResetLetterSetters();
    ResetGameSetters();
    setGameId("");
    setPseudonym("");
    setLogin(false);
    setRegister(true);
    setFetchRes(null);
    setGamePlay(false);
    setShowGames(false);
    setGuest(false);
    setLogin(false);
    setMyGameNav(false);
    setMyUpdatesNav(false);
    setNewGameNav(false);
    setShowKeyboard(false);
    setStartRandomGame(false);
    setStartSpecificGame(false);
    setStatNav(false);
    // setUpdatedGames(false);
    // setUnjoinedGames(false);
    setLoggedOut(true);
    localStorage.removeItem("token");
    localStorage.removeItem("submitterId");
    localStorage.removeItem("pseudonym");
    setJwt(null);
  };

  const execLoginLogic = () => {
    ResetLetterSetters();
    setGameId("");
    setLogin(true);
    setRegister(false);
    setFetchRes(null);
    setGamePlay(false);
    ResetGameSetters();
    setShowGames(false);
  };

  const execLogoutLogic = () => {
    setGames([]);
    setPlay([]);
    setUsersList([]);
    // setMatchingLetters([]);
    // setNonmatchingLetters([]);
    // setUnusedLetters([]);
    setUnusedTopLetters([]);
    setUnusedMidLetters([]);
    setUnusedBotLetters([]);
    setFetchRes([]);
    setPseudonym("");
    setSubmitterId("");
    setOpponentPseudonym("");
    setOpponentEmail("");
    setLetterOne("");
    setLetterTwo("");
    setLetterThree("");
    setLetterFour("");
    setLetterFive("");
    setGameId([]);
    setShowGames(false);
    setGamePlay(false);
    setJoinRandomGame(false);
    setJoinSpecificGame(false);
    setLogin(false);
    setMyGameNav(false);
    setMyUpdatesNav(false);
    setNewGameNav(false);
    setRegister(false);
    setShowKeyboard(false);
    setStartRandomGame(false);
    setStartSpecificGame(false);
    setStartJotBotGame(false);
    setStatNav(false);
    setGuest(false);
    // setUpdatedGames(false);
    // setUnjoinedGames(false);
    setLoggedOut(true);
    localStorage.removeItem("token");
    localStorage.removeItem("submitterId");
    localStorage.removeItem("pseudonym");
    setJwt(null);
  };

  const execJoinRandomLogic = () => {
    ResetLetterSetters();
    setShowKeyboard(true);
    setGameId("");
    setShowGames(false);
    setNewGameNav(false);
    setJoinRandomGame(true);
    setJoinSpecificGame(false);
    setFetchRes(null);
    setGamePlay(false);
    setStartRandomGame(false);
    setStartSpecificGame(false);
    setStartJotBotGame(false);
    // checkInvites(jwt, submitterId);
  };

  const execCreateRandomLogic = () => {
    ResetLetterSetters();
    setShowKeyboard(true);
    setGameId("");
    setShowGames(false);
    setNewGameNav(false);
    setFetchRes(null);
    setGamePlay(false);
    setStartSpecificGame(false);
    setStartJotBotGame(false);
    setStartRandomGame(true);
    setJoinRandomGame(false);
    setJoinSpecificGame(false);
    // checkInvites(jwt, submitterId);
  };

  const execCreateSpecificLogic = () => {
    getUsers();
    ResetLetterSetters();
    setShowKeyboard(true);
    setGameId("");
    setNewGameNav(false);
    setShowGames(false);
    setFetchRes(null);
    setGamePlay(false);
    setStartSpecificGame(true);
    setStartJotBotGame(false);
    setStartRandomGame(false);
    setJoinRandomGame(false);
    setJoinSpecificGame(false);
    // checkInvites(jwt, submitterId);
  };

  const execCreateJotBotLogic = () => {
    getUsers();
    ResetLetterSetters();
    setShowKeyboard(true);
    setGameId("");
    setNewGameNav(false);
    setShowGames(false);
    setFetchRes(null);
    setGamePlay(false);
    setStartSpecificGame(false);
    setStartJotBotGame(true);
    setStartRandomGame(false);
    setJoinRandomGame(false);
    setJoinSpecificGame(false);
    // checkInvites(jwt, submitterId);
  };

  const showPlayNav = (submenu) => {
    ResetLetterSetters();
    ResetGameSetters();
    setGameId("");
    setShowGames(false);
    setNewGameNav(false);
    setFetchRes(null);
    setGamePlay(false);
    if (submenu === "new") {
      setFetchRes("");
      setNewGameNav(true);
      setMyGameNav(false);
      setMyUpdatesNav(false);
      setStatNav(false);
    } else if (submenu === "current") {
      setFetchRes("");
      setNewGameNav(false);
      setMyGameNav(true);
      setMyUpdatesNav(false);
      setStatNav(false);
    } else if (submenu === "stats") {
      setFetchRes("");
      setNewGameNav(false);
      setMyGameNav(false);
      setMyUpdatesNav(false);
      setStatNav(true);
    } else if (submenu === "updates") {
      setFetchRes("");
      setNewGameNav(false);
      setMyGameNav(false);
      setMyUpdatesNav(true);
      setStatNav(false);
    }
    // checkInvites(jwt, submitterId);
  };

  /* Play as Guest */
  var randomPseudo = randomWords({ exactly: 2, join: "" });
  var randomName = randomWords({ exactly: 2, join: " " });
  var randomPwd = randomWords({ exactly: 3, join: "" });
  var randomEmail =
    randomWords({ exactly: 1, join: "" }) + "-contact@jottogame.com";

  const execPlayAsGuest = () => {
    ResetLetterSetters();
    setGameId("");
    setLogin(false);
    setRegister(false);
    setFetchRes(null);
    setGamePlay(false);
    ResetGameSetters();
    setShowGames(false);
    setGuest(true);
    getRegisterJwt(randomPseudo, randomName, randomEmail, randomPwd, true)
      .then(() => {
        setRegister(false);
        setGuest(true);
      })
      .catch(() => {
        setRegister(false);
      });
  };

  function validateLoginForm(email) {
    if (
      email
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
    }
  }

  function validateRegisterForm(email, pseudonym, password, accept) {
    if (accept === true) {
    }
  }

  function validateGuessForm() {
    return (
      letterOne.length > 0 &&
      letterTwo.length > 0 &&
      letterThree.length > 0 &&
      letterFour.length > 0 &&
      letterFive.length > 0 &&
      letterOne.length < 2 &&
      letterTwo.length < 2 &&
      letterThree.length < 2 &&
      letterFour.length < 2 &&
      letterFive.length < 2
    );
  }

  function validateMobileGuessForm() {
    return mobileWord.length === 5;
  }

  // function onlyUnique(value, index, self) {
  //   return self.indexOf(value) === index;
  // }

  async function handleRegisterSubmit(event) {
    event.preventDefault();
    var pseudonymVal = event.target[0].value;
    var nameVal = event.target[1].value;
    var emailVal = event.target[2].value;
    var passwordVal = event.target[3].value;

    await getRegisterJwt(pseudonymVal, nameVal, emailVal, passwordVal)
      .then(() => {
        setRegister(false);
      })
      .catch(() => {
        setRegister(false);
      });
  }

  async function handleLoginSubmit(event) {
    event.preventDefault();
    var emailVal = event.target[0].value;
    var passwordVal = event.target[1].value;

    await getJwt(emailVal, passwordVal)
      .then(() => {
        setLogin(false);
      })
      .catch(() => {
        setLogin(false);
      });
    // await socketEmit();
  }

  async function handleRandomJoinSubmit(event) {
    if (event) {
      event.preventDefault();
    }
    await postRequest("joinRandom")
      .then(() => {})
      .catch(() => {});
    // await socketEmit();
  }

  async function handleSpecificJoinSubmit(event) {
    if (event) {
      event.preventDefault();
    }
    await postRequest("joinSpecific")
      .then(() => {})
      .catch(() => {});
    // await socketEmit();
  }

  async function handleSpecificCreateSubmit(event) {
    if (event) {
      event.preventDefault();
    }
    await postRequest("createSpecific")
      .then(() => {})
      .catch(() => {});
    // await socketEmit();
  }

  async function handleRandomCreateSubmit(event) {
    if (event) {
      event.preventDefault();
    }
    await postRequest("createRandom")
      .then(() => {})
      .catch(() => {});
    // await socketEmit();
  }

  async function handleJotBotCreateSubmit(event) {
    if (event) {
      event.preventDefault();
    }
    await postRequest("createJotBot")
      .then(() => {})
      .catch(() => {});
    // await socketEmit();
  }
  async function handlePlaySubmit(event) {
    if (event) {
      event.preventDefault();
    }
    await postRequest("playTurn")
      .then(() => {})
      .catch(() => {});
    // await socketEmit();
  }

  /** Get Games */
  const getGames = async (status) => {
    try {
      const { data } = await axios({
        method: "get",
        url: `${apiUrl}/game`,
        headers: {
          "content-type": "application/json",
          "x-access-token": jwt,
        },
        data: {
          submitterId: submitterId,
        },
      });

      var activeGames = [];
      var turnGames = [];
      var notTurnGames = [];
      var updatedGames = [];
      var unjoinedGames = [];
      var oldGames = [];
      var returnList = [];

      for (var it = 0; it < data.length; it++) {
        var row = data[it];
        var phase = Number(row.phase);
        var whoseturn = Number(row.whoseturn);

        if (phase === 1) {
          updatedGames.push(row);
        } else if (phase === 1.5) {
          if (row.user_2 === submitterId) {
            unjoinedGames.push(row);
            updatedGames.push(row);
          }
        } else if (phase === 2) {
          activeGames.push(row);
          if (whoseturn === submitterId) {
            turnGames.push(row);
            updatedGames.push(row);
          } else {
            notTurnGames.push(row);
          }
        } else if (phase === 3) {
          oldGames.push(row);
        }
      }
      if (status === "active") {
        returnList = activeGames;
      } else if (status === "myturn") {
        returnList = turnGames;
      } else if (status === "notmyturn") {
        returnList = notTurnGames;
      } else if (status === "unjoined+myturn") {
        returnList = updatedGames;
      } else if (status === "unjoined") {
        returnList = unjoinedGames;
      } else if (status === "old") {
        returnList = oldGames;
      }

      if (returnList.length < 1) {
        setFetchRes(
          <>
            <p>No games.</p>
          </>
        );
      } else {
        setFetchRes("Pick a game!");
      }
      setNewGameNav(false);
      setMyGameNav(false);
      setMyUpdatesNav(false);
      setStatNav(false);
      setShowGames(true);
      setGames(returnList);
      ResetLetterSetters();
      setGameId("");
      setGamePlay(false);
      ResetGameSetters();
      // checkInvites(jwt, submitterId);
    } catch (err) {
      setFetchRes(err.response.data);
    }
  };

  /** Load Game */
  const loadGame = async (id) => {
    try {
      var thisGame;
      if (id === undefined) {
        thisGame = gameId;
      } else {
        thisGame = id;
      }
      const { data } = await axios({
        method: "get",
        url: `${apiUrl}/game/${thisGame}`,
        headers: {
          "content-type": "application/json",
          "x-access-token": jwt,
        },
        returning: true,
      });
      if (data.phase === 1.5) {
        setGameId(id);
        setPlay(data);
        setGameData([]);
        ResetLetterSetters();
        setFetchRes(null);
        setShowGames(false);
        setGamePlay(true);
        if (submitterId === data.user_2) {
          setShowKeyboard(true);
          setJoinSpecificGame(true);
        }
      } else {
        setGamePlay(true);
        setShowGames(false);
        setGameId(id);
        setPlay(data);
        var loadGameData = [];
        var gameDataRow;
        var myLetterMatches = [];
        var myUsedLetters = [];

        for (var key in data.game_data) {
          gameDataRow = data.game_data[key];
          loadGameData.push({ key: key, row: gameDataRow });
          if (
            gameDataRow.submitter === submitterId &&
            gameDataRow.result.length > 0 &&
            gameDataRow.result !== "Youreka!"
          ) {
            for (var iter = 0; iter < gameDataRow.result.length; iter++) {
              myLetterMatches.push(gameDataRow.result[iter]);
            }
          } else if (
            gameDataRow.submitter === submitterId &&
            gameDataRow.result.length > 0 &&
            gameDataRow.result === "Youreka!"
          ) {
            for (var ir = 0; ir < gameDataRow.string.length; ir++) {
              myLetterMatches.push(gameDataRow.string[ir]);
            }
          }

          if (
            gameDataRow.submitter === submitterId &&
            gameDataRow.string.length > 0
          ) {
            for (var it = 0; it < gameDataRow.string.length; it++) {
              myUsedLetters.push(gameDataRow.string[it]);
            }
          }
        }

        // var myMatchingLetters = myLetterMatches.filter(onlyUnique).sort();
        // var myUniqueUsed = myUsedLetters.filter(onlyUnique).sort();
        // var myNonmatchingLetters = myUniqueUsed.filter(function (el) {
        //   return !myMatchingLetters.includes(el);
        // });

        // var myUnusedLetters = myAlphabet.filter(function (el) {
        //   return !myUsedLetters.includes(el);
        // });

        var keyLayoutTop = myKeyLayoutTop.filter(function (el) {
          return !myKeyLayoutTop.includes(el);
        });

        var keyLayoutMid = myKeyLayoutMid.filter(function (el) {
          return !myKeyLayoutMid.includes(el);
        });

        var keyLayoutBot = myKeyLayoutBot.filter(function (el) {
          return !myKeyLayoutBot.includes(el);
        });

        // setMatchingLetters(myMatchingLetters);
        // setNonmatchingLetters(myNonmatchingLetters);
        // setUnusedLetters(myUnusedLetters);
        setUnusedTopLetters(keyLayoutTop);
        setUnusedMidLetters(keyLayoutMid);
        setUnusedBotLetters(keyLayoutBot);
        if (submitterId === data.whoseturn) {
          setGameData([]);
          ResetLetterSetters();
          setShowKeyboard(true);
        } else {
          setShowKeyboard(false);
        }
        setGameData(loadGameData);
        setFetchRes(<>{<p>{data.message}</p>}</>);
        // checkInvites(jwt, submitterId);
      }
    } catch (err) {
      ResetLetterSetters();
      setFetchRes(err);
    }
  };

  const getUsers = async () => {
    try {
      const { data } = await axios({
        method: "get",
        url: `${apiUrl}/user/get/all`,
        headers: {
          "x-access-token": jwt,
          "content-type": "application/json",
        },
      });
      var allUsers = [];
      allUsers.push("");
      for (var i = 0; i < data.length; i++) {
        if (pseudonym !== data[i]) {
          if (!data[i].startsWith("random")) {
            allUsers.push(data[i]);
          }
        }
      }
      setUsersList(allUsers);
    } catch (err) {
      setFetchRes(err.response.data);
    }
  };

  const postRequest = async (posttype) => {
    var thisString =
      letterOne + letterTwo + letterThree + letterFour + letterFive;

    try {
      if (posttype === "joinRandom") {
        const { data } = await axios({
          method: "post",
          url: `${apiUrl}/game/play`,
          headers: {
            "x-access-token": jwt,
            "content-type": "application/json",
          },
          data: {
            submitterId: submitterId,
            string: thisString,
            gameId: null,
            acceptingGame: false,
            myPseudonym: pseudonym,
          },
        });
        setPlay(data);
        ResetLetterSetters();
        setGameId("");
        setShowGames(false);
        ResetGameSetters();
        setGamePlay(false);
        loadGame(data.gameId);
        setFetchRes(data.message);
      } else if (posttype === "joinSpecific") {
        const { data } = await axios({
          method: "post",
          url: `${apiUrl}/game/play`,
          headers: {
            "x-access-token": jwt,
            "content-type": "application/json",
          },
          data: {
            submitterId: submitterId,
            string: thisString,
            acceptingGame: true,
            gameId: gameId,
          },
        });
        setShowKeyboard(false);
        setPlay(data);
        SetPostSetters();
        loadGame(data.gameId);
        setFetchRes(data.message);
        // checkInvites(jwt, submitterId);
      } else if (posttype === "createSpecific") {
        const { data } = await axios({
          method: "post",
          url: `${apiUrl}/game/create/specific`,
          headers: {
            "x-access-token": jwt,
            "content-type": "application/json",
          },
          data: {
            submitterId: submitterId,
            string: thisString,
            opponentPseudonym: opponentPseudonym,
            myPseudonym: pseudonym,
            opponentEmail: null,
            matchDisplay: matchDisplay,
          },
        });
        setPlay(data);
        SetPostSetters();
        loadGame(data.gameId);
        setFetchRes(data.message);
        // checkInvites(jwt, submitterId);
      } else if (posttype === "createRandom") {
        const { data } = await axios({
          method: "post",
          url: `${apiUrl}/game/create/random`,
          headers: {
            "x-access-token": jwt,
            "content-type": "application/json",
          },
          data: {
            submitterId: submitterId,
            string: thisString,
            myPseudonym: pseudonym,
            matchDisplay: matchDisplay,
          },
        });
        setFetchRes(data.message);
        setPlay(data);
        SetPostSetters();
        loadGame(data.gameId);
        // checkInvites(jwt, submitterId);
      } else if (posttype === "createJotBot") {
        const { data } = await axios({
          method: "post",
          url: `${apiUrl}/game/create/jotbot`,
          headers: {
            "x-access-token": jwt,
            "content-type": "application/json",
          },
          data: {
            submitterId: submitterId,
            string: thisString,
            myPseudonym: pseudonym,
            matchDisplay: matchDisplay,
            opponentPseudonym: "jotbot",
            opponentEmail: "",
            jotbotDifficulty: "hard",
          },
        });
        setFetchRes(data.message);
        setPlay(data);
        SetPostSetters();
        loadGame(data.gameId);
      } else if (posttype === "playTurn") {
        await axios({
          method: "post",
          url: `${apiUrl}/game/play`,
          headers: {
            "content-type": "application/json",
            "x-access-token": jwt,
          },
          data: {
            submitterId: submitterId,
            string: thisString,
            gameId: gameId,
            acceptingGame: false,
          },
        });
        setShowKeyboard(false);
        loadGame(gameId);
        // await socketEmit();
        // checkInvites(jwt, submitterId);
      } else if (posttype === "jotBotAction") {
        await axios({
          method: "post",
          url: `${apiUrl}/game/play`,
          headers: {
            "content-type": "application/json",
            "x-access-token": jwt,
          },
          data: {
            submitterId: 70,
            gameId: gameId,
            jotBotDifficulty: "hard",
          },
        });
        // setShowKeyboard(false);
        loadGame(gameId);
        // await socketEmit();
        // checkInvites(jwt, submitterId);
      }
    } catch (err) {
      ResetLetterSetters();
      setShowKeyboard(true);
      setFetchRes(err.response.data);
    }
  };

  const loadGameDiv = (
    <div className={"gameplay-container"} tabIndex="0">
      <div className="gameplay-div-container">
        {debugLevel === "debug" && (
          <>
            {/* {console.log("submitterId", submitterId)}
            {console.log("Gameplay", Play)} */}
          </>
        )}
        {
          <div key={Play.user_1} className="gameplay-div-left">
            {/** Player 1 is User 1 */}
            {Play.user_1 === submitterId && (
              <>
                <span className={"my-name-word"}>
                  <h4>
                    <span
                      className={"username-jot-board"}
                      // data-tip="This is your Jotboard, where your guesses will be listed for your reference"
                    >
                      {Play.user_1_pseudonym}
                    </span>
                    's jotboard
                  </h4>{" "}
                  <span
                    className={"my-word"}
                    // data-tip="This is your Word, which your opponent is trying to guess"
                  >
                    {Play.user_1_word}
                  </span>
                  {/* <ReactTooltip /> */}
                </span>
                {/* <span className={"myword-rows"}>
                  <ul>
                    {Play.user_1_word.split("").map((letter) => (
                      <div className="row-letter-jotto" key={letter}>
                        {letter}
                      </div>
                    ))}
                  </ul>
                </span> */}
              </>
            )}

            {/** Player 1 is User 2 */}
            {Play.user_2 === submitterId && (
              <>
                <span className={"my-name-word"}>
                  <h4>
                    <span
                      className={"username-jot-board"}
                      // data-tip="This is your Jotboard, where your guesses will be listed for your reference"
                    >
                      {Play.user_2_pseudonym}
                    </span>
                    's jotboard
                  </h4>{" "}
                  <span
                    className={"my-word"}
                    // data-tip="This is your Word, which your opponent is trying to guess"
                  >
                    {Play.user_2_word}
                  </span>
                  {/* <ReactTooltip /> */}
                </span>
                {/* <span className={"myword-rows"}>
                  <ul>
                    {Play.user_2_word.split("").map((letter) => (
                      <div className="row-letter-jotto" key={letter}>
                        {letter}
                      </div>
                    ))}
                  </ul>
                </span> */}
              </>
            )}

            {/** Game play */}
            {GameData.map(({ key, row }) => (
              <div key={key}>
                {/* Classic-Jotto - Player 1 */}
                <span className={"result-rows"}>
                  {row.string &&
                    row.submitter === submitterId &&
                    Play.match_display === false && (
                      <>
                        {" "}
                        {row.result !== "Youreka!" && row.string.length && (
                          <div className={"row-word"}>
                            {row.string.split("").map((letter) => (
                              <div className="row-letter-jotto">{letter}</div>
                            ))}
                            <span className={"row-letter-jotto result-count"}>
                              {row.resultCount}
                            </span>
                          </div>
                        )}
                        {/** Game is over */}
                        {row.result === "Youreka!" && (
                          <>
                            <div className={"row-word word-jotto youreka-row"}>
                              {row.string.split("").map((letter) => (
                                <div className="row-letter-jotto correct">
                                  {letter}
                                </div>
                              ))}
                            </div>
                            <div className={"game-outcome"}>
                              <h4>You won</h4>
                            </div>{" "}
                          </>
                        )}
                      </>
                    )}
                </span>

                {/* Wordle-Jotto - Player 1 is User 1 */}
                {row.string &&
                  row.submitter === submitterId &&
                  Play.user_1 === submitterId &&
                  Play.match_display === true && (
                    <span className={"result-rows"}>
                      {row.string && row.result !== "Youreka!" && (
                        <div className={"row-word"}>
                          {row.string.split("").map((letter, index) => (
                            <>
                              {letter === Play.user_2_word[parseInt(index)] && (
                                <div className={"row-letter-jotto correct"}>
                                  {letter}
                                </div>
                              )}
                              {letter !== Play.user_2_word[parseInt(index)] &&
                                Play.user_2_word.includes(letter) && (
                                  <div
                                    className={"row-letter-jotto sorta-correct"}
                                  >
                                    {letter}
                                  </div>
                                )}
                              {letter !== Play.user_2_word[parseInt(index)] &&
                                !Play.user_2_word.includes(letter) && (
                                  <div className={"row-letter-jotto nomatch"}>
                                    {letter}
                                  </div>
                                )}
                            </>
                          ))}
                        </div>
                      )}{" "}
                      {row.result === "Youreka!" && (
                        <>
                          <div className={"row-word word-jotto youreka-row"}>
                            {row.string.split("").map((letter) => (
                              <div className="row-letter-jotto correct">
                                {letter}
                              </div>
                            ))}
                          </div>
                          <div className={"game-outcome"}>
                            <h4>You won</h4>
                          </div>
                        </>
                      )}
                    </span>
                  )}

                {/* Wordle-Jotto - Player 1 is User 2 */}
                {row.string &&
                  row.submitter === submitterId &&
                  Play.user_2 === submitterId &&
                  Play.match_display === true && (
                    <span className={"result-rows"}>
                      {row.string && row.result !== "Youreka!" && (
                        <div className={"row-word"}>
                          {row.string.split("").map((letter, index) => (
                            <>
                              {letter === Play.user_1_word[parseInt(index)] && (
                                <div className={"row-letter-jotto correct"}>
                                  {letter}
                                </div>
                              )}
                              {letter !== Play.user_1_word[parseInt(index)] &&
                                Play.user_1_word.includes(letter) && (
                                  <div
                                    className={"row-letter-jotto sorta-correct"}
                                  >
                                    {letter}
                                  </div>
                                )}
                              {letter !== Play.user_1_word[parseInt(index)] &&
                                !Play.user_1_word.includes(letter) && (
                                  <div className={"row-letter-jotto nomatch"}>
                                    {letter}
                                  </div>
                                )}
                            </>
                          ))}
                        </div>
                      )}{" "}
                      {row.result === "Youreka!" && (
                        <>
                          <div className={"row-word word-jotto youreka-row"}>
                            {row.string.split("").map((letter) => (
                              <div className="row-letter-jotto correct">
                                {letter}
                              </div>
                            ))}
                          </div>
                          <div className={"game-outcome"}>
                            <h4>You won</h4>
                          </div>
                        </>
                      )}
                    </span>
                  )}

                {row.submitter === submitterId &&
                  Play.match_display === null && (
                    <span className={"scorecard-result"}> {row.result}</span>
                  )}
              </div>
            ))}
          </div>
        }
        {
          <div key={Play.id} className="gameplay-div-center">
            {
              <div key={"center"} className={"center"}>
                <span className="gameTypeIcon">
                  {Play.match_display === null && (
                    <>
                      <button disabled>
                        <BsFileWord />
                        <span
                          className="gameTypeDesc"
                          // data-tip="In Wordle-Jotto, matching letters will be revealed"
                        >
                          Wordle-Jotto
                        </span>{" "}
                      </button>{" "}
                      {/* <ReactTooltip /> */}
                    </>
                  )}
                  {Play.match_display === true && (
                    <>
                      <button disabled>
                        <BsFileWord />
                        <span
                          className="gameTypeDesc"
                          // data-tip="In Wordle-Jotto, matching letters will be revealed"
                        >
                          Wordle-Jotto
                        </span>{" "}
                      </button>{" "}
                      {/* <ReactTooltip /> */}
                    </>
                  )}
                  {Play.match_display === false && (
                    <>
                      <button disabled>
                        <BsPencilFill />
                        <span
                          className="gameTypeDesc"
                          // data-tip="In Classic Jotto, matching letters will not be revealed"
                        >
                          Classic Jotto
                        </span>
                      </button>
                      {/* <ReactTooltip /> */}
                    </>
                  )}
                  {Play.user_2_pseudonym === "jotbot" && (
                    <>
                      <h4 className={"jotbotGame"}>JotBot Game</h4>
                    </>
                  )}
                </span>
              </div>
            }
            {Play.phase === "1" && (
              <p className="jotPhase">Waiting for random opponent</p>
            )}
            {Play.user_2 === submitterId && Play.phase === 1.5 && (
              <p className="jotPhase">Waiting for you to accept</p>
            )}
            {Play.phase === "2" && Play.whoseturn !== submitterId && (
              <p className="jotWhoseTurn">It is not your turn</p>
            )}
            {Play.phase === "3" && <p className="jotPhase">Game Over.</p>}
            {Play.phase === "3" && Play.winner !== submitterId && (
              <p className="jotPhase">You did not win.</p>
            )}
            {Play.phase === "3" && Play.winner === submitterId && (
              <p className="jotPhase">You won.</p>
            )}
            {Play.whoseturn === submitterId && (
              <>
                {/* <FormSubtitle /> */}
                <Form className="SubmitPlay" onSubmit={handlePlaySubmit}>
                  <Form.Group size="sm">
                    <Form.Control
                      hidden
                      type="string"
                      value={submitterId}
                      onChange={(e) => setSubmitterId(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Label>Enter Word</Form.Label>
                  <div className={"form-group row"}>
                    <Form.Group size="lg">
                      <Form.Control
                        id="letterInput1"
                        type="string"
                        value={letterOne}
                        htmlSize="1"
                        maxLength="1"
                        onChange={(e) => handleTextChange("1", e)}
                      />
                    </Form.Group>
                    <Form.Group size="lg">
                      <Form.Control
                        id="letterInput2"
                        type="string"
                        value={letterTwo}
                        htmlSize="1"
                        maxLength="1"
                        onChange={(e) => handleTextChange("2", e)}
                      />
                    </Form.Group>
                    <Form.Group size="lg">
                      <Form.Control
                        id="letterInput3"
                        type="string"
                        value={letterThree}
                        htmlSize="1"
                        maxLength="1"
                        onChange={(e) => handleTextChange("3", e)}
                      />
                    </Form.Group>
                    <Form.Group size="lg">
                      <Form.Control
                        id="letterInput4"
                        type="string"
                        value={letterFour}
                        htmlSize="1"
                        maxLength="1"
                        onChange={(e) => handleTextChange("4", e)}
                      />
                    </Form.Group>
                    <Form.Group size="lg">
                      <Form.Control
                        id="letterInput5"
                        type="string"
                        value={letterFive}
                        htmlSize="1"
                        maxLength="1"
                        onChange={(e) =>
                          setLetterFive(e.target.value.toUpperCase())
                        }
                      />
                    </Form.Group>
                    {/* <button
                    className={"clear-btn"}
                    onClick={(e) => clearTiles(e)}
                  >
                    <FiDelete />
                  </button> */}
                  </div>
                  <div className="submit-container">
                    <Button
                      block="true"
                      size="lg"
                      type="submit"
                      disabled={!validateGuessForm()}
                    >
                      Jotto!
                    </Button>
                  </div>
                </Form>
              </>
            )}
            {Play.user_2 === submitterId && Play.phase === 1.5 && (
              <>
                {/* <FormSubtitle /> */}
                <Form
                  className="SubmitPlay"
                  onSubmit={handleSpecificJoinSubmit}
                >
                  <Form.Group size="sm">
                    <Form.Control
                      hidden
                      type="string"
                      value={submitterId}
                      onChange={(e) => setSubmitterId(e.target.value)}
                    />
                  </Form.Group>
                  <div className={"form-group row"}>
                    <Form.Group size="lg">
                      <Form.Control
                        id="letterInput1"
                        type="string"
                        value={letterOne}
                        htmlSize="1"
                        maxLength="1"
                        onChange={(e) => handleTextChange("1", e)}
                      />
                    </Form.Group>
                    <Form.Group size="lg">
                      <Form.Control
                        id="letterInput2"
                        type="string"
                        value={letterTwo}
                        htmlSize="1"
                        maxLength="1"
                        onChange={(e) => handleTextChange("2", e)}
                      />
                    </Form.Group>
                    <Form.Group size="lg">
                      <Form.Control
                        id="letterInput3"
                        type="string"
                        value={letterThree}
                        htmlSize="1"
                        maxLength="1"
                        onChange={(e) => handleTextChange("3", e)}
                      />
                    </Form.Group>
                    <Form.Group size="lg">
                      <Form.Control
                        id="letterInput4"
                        type="string"
                        value={letterFour}
                        htmlSize="1"
                        maxLength="1"
                        onChange={(e) => handleTextChange("4", e)}
                      />
                    </Form.Group>
                    <Form.Group size="lg">
                      <Form.Control
                        id="letterInput5"
                        type="string"
                        value={letterFive}
                        htmlSize="1"
                        maxLength="1"
                        onChange={(e) =>
                          setLetterFive(e.target.value.toUpperCase())
                        }
                      />
                    </Form.Group>
                    {/* <button
                    className={"clear-btn"}
                    onClick={(e) => clearTiles(e)}
                  >
                    <FiDelete />
                  </button> */}
                  </div>
                  <div className="submit-container">
                    <Button
                      block="true"
                      size="lg"
                      type="submit"
                      disabled={!validateGuessForm()}
                    >
                      Jotto!
                    </Button>
                  </div>
                </Form>
              </>
            )}
            {/* {console.log("play", Play)} */}
            {Play.user_1 === submitterId &&
              Play.phase !== 3 &&
              Play.user_2_pseudonym === "jotbot" &&
              Play.whoseturn !== submitterId && (
                <>
                  <div className={"jotbot-action-container"}>
                    <button
                      className={"jotbot-btn reload-btn"}
                      onClick={() => postRequest("jotBotAction")}
                      data-tip={"Tell JotBot to guess."}
                    >
                      <FaRobot />
                      <span className={"jotbutton"}>
                        Click for JotBot to guess
                      </span>
                    </button>
                    {/* <ReactTooltip /> */}
                  </div>
                </>
              )}
            {Play.phase !== "3" && Play.user_2_pseudonym !== "jotbot" && (
              <div className={"reload-container"}>
                <button
                  className={"reload-btn rotate"}
                  onClick={() => loadGame(Play.id)}
                  // data-tip={"Refresh game to see if your opponent has guessed."}
                >
                  <IoIosRefresh />
                </button>
                {/* <ReactTooltip /> */}
              </div>
            )}
          </div>
        }

        {
          <div key={Play.user_2} className="gameplay-div-right">
            {/** Player 1 is User 1 */}
            {Play.user_2 === submitterId && (
              <>
                <span className={"their-name-word"}>
                  <h4>
                    <span
                      className={"username-jot-board"}
                      // data-tip="This is your opponent's Jotboard, where their guesses will be listed for your reference"
                    >
                      {Play.user_1_pseudonym}
                    </span>
                    's jotboard
                  </h4>{" "}
                  {/* <ReactTooltip /> */}
                </span>
              </>
            )}

            {/** Player 1 is User 2 */}
            {Play.user_1 === submitterId && (
              <>
                <span className={"their-name-word"}>
                  <h4>
                    <span
                      className={"username-jot-board"}
                      // data-tip="This is your opponent's Jotboard, where their guesses will be listed for your reference"
                    >
                      {Play.user_2_pseudonym}
                    </span>
                    's jotboard
                  </h4>{" "}
                  {/* <ReactTooltip /> */}
                </span>
                {/* <span className={"myword-rows"}>
                  <ul>
                    {Play.user_1_word.split("").map((letter) => (
                      <div className="row-letter-jotto" key={letter}>
                        {letter}
                      </div>
                    ))}
                  </ul>
                </span> */}
              </>
            )}

            {/** Game play */}
            {GameData.map(({ key, row }) => (
              <div key={key}>
                {/* Classic-Jotto - Player 2 */}
                <span className={"result-rows"}>
                  {row.string &&
                    row.submitter !== submitterId &&
                    Play.match_display === false && (
                      <>
                        {row.result !== "Youreka!" && row.string.length > 0 && (
                          <div className={"row-word"}>
                            {row.string.split("").map((letter) => (
                              <div className="row-letter-jotto">{letter}</div>
                            ))}
                            <span className={"row-letter-jotto result-count"}>
                              {row.resultCount}
                            </span>
                          </div>
                        )}
                        {row.result === "Youreka!" && (
                          <>
                            <div className={"row-word word-jotto youreka-row"}>
                              {row.string.split("").map((letter) => (
                                <div className="row-letter-jotto correct">
                                  {letter}
                                </div>
                              ))}
                            </div>
                            <div className={"game-outcome"}>
                              <h4>You lost</h4>
                            </div>{" "}
                          </>
                        )}
                      </>
                    )}
                </span>

                {/* Wordle-Jotto - Player 1 is User 1 */}
                {row.string &&
                  row.submitter !== submitterId &&
                  Play.user_2 === submitterId &&
                  Play.match_display === true && (
                    <span className={"result-rows"}>
                      {row.result !== "Youreka!" && row.string && (
                        <div className={"row-word"}>
                          {row.string.split("").map((letter, index) => (
                            <>
                              {letter === Play.user_2_word[parseInt(index)] && (
                                <div className={"row-letter-jotto correct"}>
                                  {letter}
                                </div>
                              )}
                              {letter !== Play.user_2_word[parseInt(index)] &&
                                Play.user_2_word.includes(letter) && (
                                  <div
                                    className={"row-letter-jotto sorta-correct"}
                                  >
                                    {letter}
                                  </div>
                                )}
                              {letter !== Play.user_2_word[parseInt(index)] &&
                                !Play.user_2_word.includes(letter) && (
                                  <>
                                    <div className={"row-letter-jotto nomatch"}>
                                      {letter}
                                    </div>
                                  </>
                                )}
                            </>
                          ))}
                        </div>
                      )}{" "}
                      {row.result === "Youreka!" && (
                        <>
                          <div className={"row-word word-jotto youreka-row"}>
                            {row.string.split("").map((letter) => (
                              <div className="row-letter-jotto correct">
                                {letter}
                              </div>
                            ))}
                          </div>
                          <div className={"game-outcome"}>
                            <h4>You lost</h4>
                          </div>{" "}
                        </>
                      )}
                    </span>
                  )}

                {/* Wordle-Jotto - Player 1 is User 2 */}
                {row.string &&
                  row.submitter !== submitterId &&
                  Play.user_1 === submitterId &&
                  Play.match_display === true && (
                    <span className={"result-rows"}>
                      {row.result !== "Youreka!" && row.string.length > 0 && (
                        <div className={"row-word"}>
                          {row.string.split("").map((letter, index) => (
                            <>
                              {letter === Play.user_1_word[parseInt(index)] && (
                                <div className={"row-letter-jotto correct"}>
                                  {letter}
                                </div>
                              )}
                              {letter !== Play.user_1_word[parseInt(index)] &&
                                Play.user_1_word.includes(letter) && (
                                  <div
                                    className={"row-letter-jotto sorta-correct"}
                                  >
                                    {letter}
                                  </div>
                                )}
                              {letter !== Play.user_1_word[parseInt(index)] &&
                                !Play.user_1_word.includes(letter) && (
                                  <div className={"row-letter-jotto nomatch"}>
                                    {letter}
                                  </div>
                                )}
                            </>
                          ))}
                        </div>
                      )}{" "}
                      {row.result === "Youreka!" && (
                        <>
                          <div className={"row-word word-jotto youreka-row"}>
                            {row.string.split("").map((letter) => (
                              <div className="row-letter-jotto correct">
                                {letter}
                              </div>
                            ))}
                          </div>
                          <div className={"game-outcome"}>
                            <h4>You lost</h4>
                          </div>{" "}
                        </>
                      )}
                    </span>
                  )}

                {row.submitter !== submitterId &&
                  Play.match_display === null && (
                    <span className={"scorecard-result"}> {row.result}</span>
                  )}
              </div>
            ))}
          </div>
        }
        {
          <div key={Play.phase} className="gameplay-div-mobile">
            {Play.phase === "1" && (
              <p className="jotPhase">Waiting for random opponent</p>
            )}
            {Play.user_2 === submitterId && Play.phase === 1.5 && (
              <p className="jotPhase">Waiting for you to accept</p>
            )}
            {Play.phase === "2" && Play.whoseturn !== submitterId && (
              <p className="jotWhoseTurn">It is not your turn</p>
            )}
            {Play.phase === "3" && <p className="jotPhase">Game Over.</p>}
            {Play.phase === "3" && Play.winner !== submitterId && (
              <p className="jotPhase">You did not win.</p>
            )}
            {Play.phase === "3" && Play.winner === submitterId && (
              <p className="jotPhase">You won.</p>
            )}
            <p className="gameTypeIcon">
              {Play.match_display === false && (
                <>
                  <button disabled>
                    <BsPencilFill />
                    <span className="gameTypeDesc">Classic Jotto</span>
                  </button>
                </>
              )}
              {Play.match_display === true && (
                <>
                  <button disabled>
                    <BsFileWord />
                    <span className="gameTypeDesc">Wordle-Jotto</span>
                  </button>
                </>
              )}
            </p>
            {Play.user_2_pseudonym === "jotbot" && (
              <>
                <h4 className={"jotbotGame"}>JotBot Game</h4>
              </>
            )}
            {Play.whoseturn === submitterId && (
              <Form className="SubmitPlay" onSubmit={handlePlaySubmit}>
                <Form.Group size="sm">
                  <Form.Control
                    hidden
                    type="string"
                    value={submitterId}
                    onChange={(e) => setSubmitterId(e.target.value)}
                  />
                </Form.Group>
                <Form.Label>Enter Word</Form.Label>
                <div className={"form-group row"} key={"mobileWordDiv"}>
                  <Form.Group size="lg">
                    <Form.Control
                      key={"mobileWord"}
                      // autoFocus
                      type="string"
                      value={mobileWord.toUpperCase()}
                      maxLength="5"
                      onBlur={(e) => inputHandler(e, "word", "mobileword")}
                      onChange={(e) => changeHandler(e, "word")}
                    />
                  </Form.Group>
                </div>
                <div className="submit-container">
                  <Button
                    block="true"
                    size="lg"
                    type="submit"
                    disabled={!validateMobileGuessForm()}
                  >
                    Jotto!
                  </Button>
                </div>
              </Form>
            )}
            {Play.phase !== "3" && Play.user_2_pseudonym !== "jotbot" && (
              <div className={"reload-container"}>
                <button
                  className={"reload-btn rotate"}
                  onClick={() => loadGame(Play.id)}
                  // data-tip={"Refresh game to see if your opponent has guessed."}
                >
                  <IoIosRefresh />
                </button>
                {/* <ReactTooltip /> */}
              </div>
            )}
            {Play.user_2 === submitterId && Play.phase === 1.5 && (
              <Form className="SubmitPlay" onSubmit={handleSpecificJoinSubmit}>
                <Form.Group size="sm">
                  <Form.Control
                    hidden
                    type="string"
                    value={submitterId}
                    onChange={(e) => setSubmitterId(e.target.value)}
                  />
                </Form.Group>
                <Form.Label>Enter Word</Form.Label>
                <div className={"form-group row"} key={"mobileWordDiv"}>
                  <Form.Group size="lg">
                    <Form.Control
                      key={"mobileWord"}
                      // autoFocus
                      type="string"
                      value={mobileWord.toUpperCase()}
                      maxLength="5"
                      onBlur={(e) => inputHandler(e, "word", "mobileword")}
                      onChange={(e) => changeHandler(e, "word")}
                    />
                  </Form.Group>
                </div>
                <div className="submit-container">
                  <Button
                    block="true"
                    size="lg"
                    type="submit"
                    disabled={!validateMobileGuessForm()}
                  >
                    Jotto!
                  </Button>
                </div>
              </Form>
            )}
            {Play.user_1 === submitterId &&
              Play.phase !== "3" &&
              Play.user_2_pseudonym === "jotbot" &&
              Play.whoseturn !== submitterId && (
                <>
                  <div className={"jotbot-action-container"}>
                    <button
                      className={"jotbot-btn reload-btn"}
                      onClick={() => postRequest("jotBotAction")}
                      data-tip={"Tell JotBot to guess."}
                    >
                      <FaRobot />
                      <span className={"jotbutton"}>
                        Click for JotBot to guess
                      </span>
                    </button>
                    {/* <ReactTooltip /> */}
                  </div>
                </>
              )}
          </div>
        }
      </div>
    </div>
  );

  function shareJot() {
    setShowShare(true);
  }

  function showFeedback() {
    setShowFeedback(true);
  }
  const footerDiv = (
    <div className={"footer-container"} tabIndex="1">
      {isGuest === true && (
        <>
          <span className="register-link">
            Don't have an account?{" "}
            <button onClick={() => execRegisterLogic()}>Register</button>
          </span>
        </>
      )}
      {isLoggedOut === true && isGuest !== true && isRegister !== true && (
        <>
          <span className="register-link">
            Don't have an account?{" "}
            <button onClick={() => execRegisterLogic()}>Register</button>
          </span>
        </>
      )}
      {
        <button className={"shareButton"} onClick={() => shareJot()}>
          <FaShare />
          Share Jotto
        </button>
      }
      {
        <button className={"shareButton"} onClick={() => showFeedback()}>
          <MdReportProblem />
          Report A Bug
        </button>
      }
      <span className={"footer-copyright"}>
        © 2023 Henry Perlstein - v{process.env.REACT_APP_VERSION}
      </span>
    </div>
  );

  const jottoApp = (
    <div>
      {jwt ? (
        <>
          {isLoggedOut === true && isGuest !== true && isRegister !== true && (
            <LogoContainer />
          )}
          <NavBar status="loggedIn" />
        </>
      ) : (
        <>
          <LogoContainer />
          <NavBar status="loggedOut" />
        </>
      )}
      <div className="app loader">
        {isLogin ? loginFormDiv : null}
        {isRegister ? registerFormDiv : null}
        {isShowGames ? showAllGamesDiv : null}
        {isGamePlay ? loadGameDiv : null}
        {isStartRandomGame ? createRandomGameDiv : null}
        {isStartJotBotGame ? createChallengeJotBot : null}
        {isStartSpecificGame ? createSpecificGameDiv : null}
        {isJoinRandomGame ? joinRandomGameDiv : null}
        {isNewGameNav ? newGameNav : null}
        {isMyGameNav ? myGameNav : null}
        {isMyUpdatesNav ? myUpdatesNav : null}
        {isStatNav ? statNav : null}
        {showResDiv}
        {isShowKeyboard ? myKeyboardDiv : null}
        {isShowShare ? shareDiv : null}
        {isShowFeedback ? feedbackDiv : null}

        {footerDiv}
      </div>
    </div>
  );

  return jottoApp;
};

export default Jotto;
